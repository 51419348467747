import React from 'react';

import UeiCard from './UeiCard';
import PlusCard from './PlusCard';
import AddPopup from './AddPopup';

class CardList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showAddPopup: false
        };
    }

    componentDidMount() {
        this.props.refreshDevice();
    }

    setOverlay = (overlayFlag) => () => {
        this.setState({showAddPopup: overlayFlag});
    }

    render() {
        if (this.props.showSpinner) {
            return (
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '50vh'}}>
                    <div className="spinner-border text-secondary" role="status" />
                </div>
            );
        }

        return (
        <>
            <div className="album py-5 bg-light" style={{position: 'relative'}}>
                <div className="container">
                    <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
                        {this.props.deviceList.map(device =>
                            <UeiCard
                                key={`uei-card-${device.deviceId}`}
                                deviceId={device.deviceId}
                                deviceType={device.deviceType}
                                deviceName={device.deviceName}
                                resources={device.data}
                                disabled={!device.online || !device.setup_done}
                                setupRequired={device.setup_required && !device.setup_done}
                                refreshDevice={this.props.refreshDevice}
                                username={this.props.username}
                            />
                        )}
                        <PlusCard showAddPopup={this.setOverlay(true)} />
                    </div>
                    {this.state.showAddPopup && <AddPopup hidePopup={this.setOverlay(false)} username={this.props.username} refreshDevice={this.props.refreshDevice} deviceList={this.props.deviceList} />}
                </div>
            </div>
        </>
        )
    }
}

export default CardList;
