export function isValidJson (test) {
    if (typeof test !== 'string') {
        // Ignore silently
        return false;
    }

    try {
        const parsed = JSON.parse(test);

        // JSON.parse('true') and JSON.parse('null') do not throw error
        return typeof parsed === 'object' && parsed !== null;
    } catch {
        return false;
    }
}

// Imported from com.webos.service.homeconnect
// Use for converting bool-like value to boolean value and vice versa
export const BoolTool = {
	checkType: function(val) {
		switch( typeof val ) {
			case 'boolean':
				return 1;
			case 'number':
				return 2;
			default:
				if (val === 'true' || val === 'false') {
					return 3;
				} else if (val === '0' || val === '1') {
					return 4;
				} else if (val === 'on' || val === 'off') {
					return 5;
				} else if (val === 'ON' || val === 'OFF') {
					return 6;
				}
		}

		return 1;
	},

	boolValue: function(value, type) {
		switch( type ) {
			case 2:
				return value !== 0;

			case 3: // fall-throught
			case 4: // fall-throught
			case 5: // fall-throught
			case 6: // fall-throught
				return value === '1' || value === 'true' || value === 'on' || value === 'ON';

			case 1: // fall-throught
			default: // fall-throught
				break;
		}

		return value;
	},

	originalValue: function(boolVal, type) {
		switch (type) {
			case 2:
				return boolVal ? 1 : 0;
			case 3:
				return boolVal ? 'true' : 'false';
			case 4:
				return boolVal ? '1' : '0';
			case 5:
				return boolVal ? 'on' : 'off';
			case 6:
				return boolVal ? 'ON' : 'OFF';
			default:
				break;
		}

		return boolVal;
	},

	anotherOriginal: function(boolVal, type) {
		switch (type) {
			case 2:
				return boolVal ? 0 : 1;
			case 3:
				return boolVal ? 'false' : 'true';
			case 4:
				return boolVal ? '0' : '1';
			case 5:
				return boolVal ? 'off' : 'on';
			case 6:
				return boolVal ? 'OFF' : 'ON';
			default:
				break;
		}

		return !boolVal;
	}
};
