import React from 'react';
import {metaManager} from '../Core/metaManager';
import {BoolTool} from '../Core/tool';

class ResourcePicker extends React.Component {
    onValueChange = (ev) => {
        const displayValue = ev.target.value;
        const parsedInt = parseInt(displayValue);
        if (!isNaN(parsedInt)) {
            this.props.handleChanges(this.props.resValueTag, parsedInt);
            return;
        }

        if (displayValue === 'true' || displayValue === 'false') {
            this.props.handleChanges(this.props.resValueTag, displayValue === 'true' ? true : false);
            return;
        }

        this.props.handleChanges(this.props.resValueTag, displayValue);
    }

    convertToBool = (value) => {
        const boolType = BoolTool.checkType(value);
        const boolValue = BoolTool.boolValue(value, boolType);
        return boolValue;
    }

    render() {
        const {deviceId, resId, value} = this.props;
        if (resId === 'mode') {
            return null;
        }

        const resourceMeta = metaManager.getResourceMetaById(resId);

        if (!resourceMeta) {
            return null;
        }

        let valueRange = [];

        if (resourceMeta.vt === 'number') {
            for (let i = resourceMeta.vr[0]; i <= resourceMeta.vr[1]; i++) {
                valueRange.push(i);
            }
        }

        if (resourceMeta.vt === 'bool') {
            resourceMeta.vr.forEach(e => valueRange.push(`${e}`));
        }

        if (resourceMeta.vt === 'binary') {
            resourceMeta.vr.forEach(e => valueRange.push(`${e}`));
        }

        if (resourceMeta.vt === 'enum') {
            resourceMeta.vr.forEach(e => valueRange.push(`${e}`));
        }

        // Suppress value types not supported
        if (valueRange.length === 0) {
            return null;
        }

        let displayValue;
        if (valueRange.includes(value) || valueRange.includes(`${value}`)) {
            displayValue = value;
        }

        return (
            <div className="input-group mb-3" key={this.props.key}>
                <span className="input-group-text" style={{width: '65%'}}>{metaManager.resourceNameToStringName(resourceMeta.name)}</span>
                <select className="form-select" style={{textAlign: 'center'}} value={displayValue} onChange={this.onValueChange}>
                    {valueRange.map(e => <option disabled={this.props.disabled} key={`res-dropdown-option-${deviceId}-${resId}-${e}`}>{e}</option>)}
                </select>
            </div>
        );
    }
}

export default ResourcePicker;

