import React from 'react';
import './css/PlusCard.css';

class PlusCard extends React.Component {
    render() {
        return (
            <div className="col plusColumn">
                <div role="button" className="card shadow-sm plusButton btn btn-sm btn-outline-secondary" onClick={this.props.showAddPopup}>
                    +
                </div>
            </div>
        )
    }
}

export default PlusCard;
