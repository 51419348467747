export const metaManager = {
    _resourceMeta: [],
    _deviceMeta: [],
    _stringMap: {},
    setResourceMeta: function (newMeta) {
        if (!newMeta) {
            return;
        }
        metaManager._resourceMeta = newMeta;
    },
    setDeviceMeta: function (newMeta) {
        if (!newMeta) {
            return;
        }
        metaManager._deviceMeta = newMeta;
    },
    setStringMap: function (newMeta) {
        if (!newMeta) {
            return;
        }
        metaManager._stringMap = newMeta;
    },
    getResourceMetaById: function (resourceId) {
        return metaManager._resourceMeta.find(res => res.id === resourceId);
    },
    getResourceMetaByValueTag: function (valueTag) {
        return metaManager._resourceMeta.find(res => res.uei && res.uei.valueTag === valueTag);
    },
    getDeviceMetaList: function () {
        return metaManager._deviceMeta;
    },
    getDeviceMetaByDeviceType: function (deviceType) {
        return metaManager._deviceMeta.find(device => device.deviceType === deviceType);
    },
    resourceNameToStringName: function (resourceName) {
        return metaManager._stringMap.resource[resourceName];
    }
};
