import React from 'react';
import {hexMd5} from '../lib/md5.js';

import {metaManager} from '../Core/metaManager';
import {ueiRequest} from '../Core/requestServer';
import './css/AddPopup.css';

class AddPopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showDropdown: false,
            selectedDeviceType: '',
            enteredDeviceId: '',
            enteredAlias: ''
        }
    }

    onClickPopupBox = (ev) => {
        ev.stopPropagation();
    }

    onToggleDropdown = () => {
        this.setState(prevState => ({showDropdown: !prevState.showDropdown}));
    }

    onClickDropdownItem = (deviceType, name) => () => {
        this.setState({selectedDeviceType: deviceType, enteredDeviceId: `${name ? name : deviceType}-${hexMd5('' + deviceType + Date.now())}`});
    }

    applyChanges = () => {
        if (this.state.selectedDeviceType.length > 0 && this.state.enteredDeviceId.length > 0) {
            const alias = this.state.enteredAlias.length > 0 ? this.state.enteredAlias : this.state.enteredDeviceId;
            ueiRequest.addDevice(this.props.username, this.state.enteredDeviceId, this.state.selectedDeviceType, '', alias, () => {
                this.props.refreshDevice();
                this.props.hidePopup();
            });
        }
    }

    onDeviceIdChange = (ev) => {
        this.setState({enteredDeviceId: ev.target.value});
    }

    onAliasChange = (ev) => {
        this.setState({enteredAlias: ev.target.value});
    }

    render() {
        return (
            <div className="addPopupOverlay" onClick={this.props.hidePopup}>
                <div className="card shadow-sm addPopupCard" onClick={this.onClickPopupBox}>
                    <div className="card-body">
                        <h4 className="fw-light">Add New Device</h4>
                        <br />
                        <div className="d-flex justify-content-between align-items-center">
                            <h5 className="fw-light">Device Type</h5>
                            <div className={this.state.showDropdown ? "dropdown show" : "dropdown"} onClick={this.onToggleDropdown} >
                                <button className="btn btn-secondary dropdown-toggle" type="button">
                                {this.state.selectedDeviceType.length > 0 ? `${this.state.selectedDeviceType} (${metaManager.getDeviceMetaByDeviceType(this.state.selectedDeviceType).name})` : 'Select a device type'}
                                </button>
                                <ul className={this.state.showDropdown ? "dropdown-menu show" : "dropdown-menu"}>
                                    {metaManager.getDeviceMetaList().map(device =>
                                        <li key={`dropdownItem-${device.deviceType}`}><div className="dropdown-item" role="button" onClick={this.onClickDropdownItem(device.deviceType, device.name)}>{`${device.deviceType} (${device.name})`}</div></li>
                                    )}
                                </ul>
                            </div>
                        </div>
                        <br />
                        <div className="d-flex justify-content-between align-items-center">
                            <h5 className="fw-light">Device Id</h5>
                            <input type="text" className="form-control" style={{width: "50%"}} id="input-deviceId" placeholder="Enter a unique device id" value={this.state.enteredDeviceId} onChange={this.onDeviceIdChange} />
                        </div>
                        <br />
                        <div className="d-flex justify-content-between align-items-center">
                            <h5 className="fw-light">Alias</h5>
                            <input type="text" className="form-control" style={{width: "50%"}} id="input-alias" placeholder="Enter an alias for the device" value={this.state.enteredAlias} onChange={this.onAliasChange} />
                        </div>
                        <br />
                        <div className="d-flex justify-content-center align-items-center">
                            <button type="button" className="btn btn-sm btn-outline-secondary" onClick={this.applyChanges}>Apply</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default AddPopup;
