import React from 'react';

class NavigationBar extends React.Component {
    render() {
        return (
            <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
                <div className="container-fluid">
                    <span className="navbar-brand">Local IoT Device Faker</span>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <span className="nav-link" type="button" onClick={this.props.moveToLoginPage}>Home</span>
                            </li>
                            <li className="nav-item">
                                <span className="nav-link" type="button" onClick={this.props.refreshDevice}>Refresh All</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        )
    }
}

export default NavigationBar;
