import React from 'react';

export default class LoginPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            username: '',
            errorMessage: ''
        }
    }

    onUsernameChange = (ev) => {
        this.setState({username: ev.target.value});
    }

    onSubmit = (event) => {
        let errorMessage = '';
        if (this.state.username.length === 0) {
            errorMessage = 'Please enter username';
        } else if (!this.state.username.match(/^[a-zA-Z0-9]/)) {
            errorMessage = 'Username must start with alphanumerals';
        } else if (this.state.username.match(/[^a-zA-Z0-9_.]/g)) {
            errorMessage = 'Username does not accept special characters other than underscores and dots';
        } else if (this.state.username.match(/[.]{2,}|[_]{2,}/g)) {
            errorMessage = 'Username does not accept consecutive special characters';
        }

        if (errorMessage.length > 0) {
            this.setState({errorMessage});
            event.preventDefault();
            return;
        }

        this.props.setUsername(this.state.username);
    }

    render() {
        return (
            <div className="container">
                <main>
                    <div className="py-5 text-center">
                    <h2>{this.props.appTitle}</h2>
                    <p className="lead">A tool for testing IoT devices intended to discover on local network.</p>
                    </div>

                    <div className="row g-5">
                        <div className="col-md-10 col-lg d-flex justify-content-center">
                            <form className="col-sm-7 needs-validation d-flex justify-content-center " onSubmit={this.onSubmit} noValidate="">
                                <div className="col-sm-7">
                                    <label htmlFor="input-username" className="form-label">Username</label>
                                    <input type="text" className={this.state.errorMessage.length > 0 ? 'form-control is-invalid' : 'form-control'} id="input-username" placeholder="username" value={this.state.username} onChange={this.onUsernameChange} />
                                    <div className="invalid-feedback">
                                        {this.state.errorMessage}
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                    <hr className="my-4" />
                </main>

                <div className="col-sm d-flex justify-content-center">
                    <button className="btn btn-primary" onClick={this.onSubmit} type="submit" onSubmit={this.onSubmit}>Submit</button>
                </div>

                <footer className="my-5 pt-5 text-muted text-center text-small">
                    <p className="mb-1">This tool is intended for testing purpose only. This tool does not guarantee the correctness of actual devices or interactions between controller and controllee. Always verify with actual devices.</p>
                </footer>
            </div>
        )
    }
}