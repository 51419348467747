import React from 'react';
import ReactDOM from 'react-dom';
import './local/bootstrap/css/bootstrap.css';
import LocalApp from './local/App';

const path = window.location.pathname;

// Either root or /local
if (path === '/local' || path === '/') {
   ReactDOM.render(
      <LocalApp />,
      document.getElementById('root')
   );
} else {
   ReactDOM.render(
      <DefaultApp />,
      document.getElementById('root')
   );
}

function DefaultApp () {
   return (<div>Invalid Path: {path}</div>);
}

