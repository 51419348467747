import https from 'https';
import {metaManager} from './metaManager';

// const host = '13.125.115.89';
const host = 'mock.lgiot.tv';

export const ueiRequest = {
    getAllIoTDevices: function (username, cb) {
        const postData = JSON.stringify({username: username});
        console.log('### sending username', postData);
        const req = https.request({
            host: host,
            port: 3000,
            path: '/uei/getAllIoTDevices',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Content-Length': Buffer.byteLength(postData)
            }
        },
        res => {
            if (res.statusCode !== 200) {
                console.log('### Inivalid statusCode', res.statusCode);
                if (cb) cb({returnValue: false});
                return;
            }

            res.on('error', err => {
                console.error(`Internal Error: ${err}`);
                return;
            });

            let body = [];

            res.on('data', chunk => {
                body.push(chunk);
            })
            .on('end', () => {
                body = Buffer.concat(body).toString();
                const jsonBody = JSON.parse(body);
                console.log('### getAllIoTDevices response: ', jsonBody);
                if (cb) cb({
                    returnValue: true,
                    devices: jsonBody.device,
                    newUser: jsonBody.newUser
                });
            });
        });

        req.on('error', err => {
            console.error(`Internal Error: ${err}`);
            return;
        });

        req.write(postData);
        req.end();
    },

    setIoTDevice: function (username, deviceId, data, cb) {
        const postData = JSON.stringify({deviceId, data, username});
        console.log('## sending', postData);

        const req = https.request({
            host: host,
            port: 3000,
            path: '/uei/setIoTDevice',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Content-Length': Buffer.byteLength(postData)
            }
        }, res => {
            let body = [];
            res.on('data', chunk => {
                body.push(chunk);
            });
            res.on('end', () => {
                body = Buffer.concat(body).toString();
                const jsonBody = JSON.parse(body);
                if (cb) cb({
                    returnValue: true
                });
            });
        });

        req.on('error', err => {
            console.log(err);
            if (cb) cb({
                returnValue: false
            });
        });

        req.write(postData);
        req.end();
    },

    deleteDevice: function (username, deviceId, cb) {
        const postData = JSON.stringify({deviceId, username, selected: 3});
        console.log('## sending', postData);

        const req = https.request({
            host: host,
            port: 3000,
            path: '/uei/selectIoTDevice',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Content-Length': Buffer.byteLength(postData)
            }
        }, res => {
            let body = [];
            res.on('data', chunk => {
                body.push(chunk);
            });
            res.on('end', () => {
                body = Buffer.concat(body).toString();
                const jsonBody = JSON.parse(body);
                if (cb) cb({
                    returnValue: true
                });
            });
        });

        req.on('error', err => {
            console.log(err);
            if (cb) cb({
                returnValue: false
            });
        });

        req.write(postData);
        req.end();
    },

    authorizeDevice: function (username, deviceId, cb) {
        const postData = JSON.stringify({deviceId, username, selected: 1});
        console.log('## sending', postData);

        const req = https.request({
            host: host,
            port: 3000,
            path: '/uei/selectIoTDevice',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Content-Length': Buffer.byteLength(postData)
            }
        }, res => {
            let body = [];
            res.on('data', chunk => {
                body.push(chunk);
            });
            res.on('end', () => {
                body = Buffer.concat(body).toString();
                const jsonBody = JSON.parse(body);
                console.log('### selectIoTDevice response: ', jsonBody);

                if (jsonBody.errorMsg) {
                    console.error('[Error] selectIoTDevice error: ', jsonBody.errorMsg);
                }

                if (cb) cb({
                    returnValue: jsonBody.returnValue
                });
            });
        });

        req.on('error', err => {
            console.log(err);
            if (cb) cb({
                returnValue: false
            });
        });

        req.write(postData);
        req.end();
    },

    getAppResourceMeta: function (spec, cb) {
        const req = https.request({
            host: host,
            port: 3000,
            path: '/uei/getResourceMeta',
            method: 'GET'
        },
        res => {
            if (res.statusCode !== 200) {
                console.log('### Inivalid statusCode', res.statusCode);
                if (cb) cb({returnValue: false});
                return;
            }

            let body = [];

            res.on('data', chunk => {
                body.push(chunk);
            })
            .on('end', () => {
                body = Buffer.concat(body).toString();
                const jsonBody = JSON.parse(body);
                console.log('### getResoruceMeta response: ', jsonBody);

                if (!Array.isArray(jsonBody.resources) || !Array.isArray(jsonBody.devices)) {
                    if (cb) cb({returnValue: false});
                    return;
                }

                // Load data to local
                metaManager.setResourceMeta(jsonBody.resources);
                metaManager.setDeviceMeta(jsonBody.devices);
                metaManager.setStringMap(jsonBody.stringMap);

                if (cb) cb({
                    returnValue: true,
                    resources: jsonBody.resources
                });
            });
        });

        req.on('error', err => {
            console.error(`Internal Error: ${err}`);
            return;
        });

        req.end();
    },

    addDevice: function (username, deviceId, deviceType, setupMethod, alias, cb) {
        const postData = JSON.stringify({deviceId, deviceType, setupMethod, username, alias});
        console.log('## sending', postData);

        const req = https.request({
            host: host,
            port: 3000,
            path: '/uei/addDevice',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Content-Length': Buffer.byteLength(postData)
            }
        }, res => {
            let body = [];
            res.on('data', chunk => {
                body.push(chunk);
            });
            res.on('end', () => {
                body = Buffer.concat(body).toString();
                const jsonBody = JSON.parse(body);
                console.log('### addDevice response: ', jsonBody);
                if (cb) cb({
                    returnValue: true
                });
            });
        });

        req.on('error', err => {
            console.log(err);
            if (cb) cb({
                returnValue: false
            });
        });

        req.write(postData);
        req.end();
    },
}
