import React from 'react';
import ResourcePicker from './ResourcePicker';
import {ueiRequest} from '../Core/requestServer';
import {metaManager} from '../Core/metaManager';
import './css/UeiCard.css';

class UeiCard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            resources: {
                ...this.props.resources
            }
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.resources !== this.props.resources) {
            this.setState({resources: this.props.resources});
        }
    }

    handleChanges = (valueTag, newValue) => {
        this.setState((prevState) => ({
            resources: {
                ...prevState.resources,
                [valueTag]: newValue
            }
        }));
    }

    resetChanges = () => {
        this.setState({resources: this.props.resources});
    }

    applyChanges = () => {
        let newData = {};
        Object.keys(this.props.resources).forEach(res => {
            newData[res] = this.state.resources[res];
        });

        ueiRequest.setIoTDevice(this.props.username, this.props.deviceId, newData, () => {
            this.props.refreshDevice();
        });
    }

    deleteDevice = () => {
        ueiRequest.deleteDevice(this.props.username, this.props.deviceId, () => {
            this.props.refreshDevice();
        });
    }

    authorizeDevice = () => {
        // Use for buttonAndPress?
    }

    render () {
        const {deviceId, deviceType, deviceName, disabled} = this.props;
        const {resources} = this.state;
        const backgroundColor = disabled ? '#e0e0e0' : 'white'
        const spec = 'UEI';
        const deviceMeta = metaManager.getDeviceMetaByDeviceType(deviceType);
        const availableResources = deviceMeta.resources.reduce((prev, curr) => {
            const resIdMatch = metaManager.getResourceMetaById(curr.id);
            if (resIdMatch) {
                return prev.concat(resIdMatch);
            }
            return prev;
        }, []);

        return (
            <div className="col">
                <div className="card shadow-sm">
                    <div className="card-body" style={{backgroundColor}}>
                        <h4 className="fw-light">{deviceType}</h4>
                        <h5 className="fw-light">{deviceName}</h5>
                        <article>
                            {Object.keys(resources).map(resValueTag => {
                                // Find resource meta from resources list specified in device meta
                                const resMeta = availableResources.find(res => res && res.uei && res.uei.valueTag === resValueTag);
                                if (!resMeta) return null;

                                const resId = resMeta.id;
                                return resId && <ResourcePicker key={`res-input-${deviceId}-${resId}`} disabled={this.props.disabled} deviceId={deviceId} resId={resId} resValueTag={resValueTag} value={resources[resValueTag]} handleChanges={this.handleChanges} />
                            })}
                        </article>
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="btn-group">
                                <button type="button" className="btn btn-sm btn-outline-secondary" disabled={this.props.disabled} onClick={this.resetChanges}>Reset</button>
                                <button type="button" className="btn btn-sm btn-outline-secondary" disabled={this.props.disabled} onClick={this.applyChanges}>Apply</button>
                                <button type="button" className="btn btn-sm btn-outline-secondary deleteButton" onClick={this.deleteDevice}>Delete</button>
                                {this.props.setupRequired && <button type="button" className="btn btn-sm btn-outline-secondary authorizeButton" onClick={this.authorizeDevice}>Authorize</button>}
                            </div>
                            <small className="text-muted">{spec}</small>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default UeiCard;
