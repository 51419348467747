import React from 'react';

import {ueiRequest} from './Core/requestServer';
import LoginPage from './views/LoginPage';
import CardList from './views/CardList';
import NavigationBar from './views/NavigationBar';

class App extends React.Component {
    constructor(props) {
        super(props);

        setTimeout(() => {
            if (this.state.showSpinner) {
                this.setState({showFail: true});
            }
        }, 10000);

        ueiRequest.getAppResourceMeta('uei', () => {
            this.timer = null;
            this.setState({showSpinner: false});
        });

        this.state = {
            showSpinner: true,
            showFail: false,
            username: '',
            deviceList: [],
            pageIndex: 0
        }
    }

    setUsername = (username) => {
        this.setState({username: username, pageIndex: 1});
    }

    moveToLoginPage = () => {
        this.setState({username: '', pageIndex: 0});
    }

    refreshDevice = () => {
        this.setState({showSpinner: true});
        ueiRequest.getAllIoTDevices(this.state.username, res => {
            if (res.returnValue === true) {
                this.setState({deviceList: res.devices});
            }
            this.setState({showSpinner: false});
        });
    }

    render () {
        const appTitle = 'Local IoT Device Faker';

        if (this.state.showFail) {
            return (
                <div>
                    [Internal Error] Failed to load data from server.
                </div>
            )
        }

        if (this.state.pageIndex === 0) {
            return (
                <LoginPage appTitle={appTitle} setUsername={this.setUsername} />
            );
        }

        return (
            <>
                <NavigationBar refreshDevice={this.refreshDevice} moveToLoginPage={this.moveToLoginPage} />
                <CardList showSpinner={this.state.showSpinner} refreshDevice={this.refreshDevice} deviceList={this.state.deviceList} username={this.state.username} />
            </>
        )
    }
}

export default App;
